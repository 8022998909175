import(/* webpackMode: "eager" */ "/vercel/path0/apps/fisiocrem/lib/ui/live-preview/preview-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/fisiocrem/lib/ui/theme.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/fisiocrem/styles/global.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.3.1_next@15.0.4_@opentelemetry+api@1.9.0_@playwright+test@1.48.1_react-do_qmak5ifbnu7ja32jk4bmozdzsq/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.0.12_next@15.0.4_@opentelemetry+api@1.9.0_@playwright+test@1.48.1_re_vla5r6o5ufqjdph5sg2337nnkq/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next-sanity@7.1.4_@sanity+client@6.22.1_@sanity+icons@3.4.0_react@18.3.1__@sanity+types@3.55._rnpfnjw5iy7jpcmixqpfqv7mxy/node_modules/next-sanity/dist/visual-editing/VisualEditing.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.6_@babel+core@7.24.5_@opentelemetry+api@1.9.0_@playwright+test@1.48.1_babel-plugin-_tduhbpbizwvnvb6gxbmkrgchqe/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.6_@babel+core@7.24.5_@opentelemetry+api@1.9.0_@playwright+test@1.48.1_babel-plugin-_tduhbpbizwvnvb6gxbmkrgchqe/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.6_@babel+core@7.24.5_@opentelemetry+api@1.9.0_@playwright+test@1.48.1_babel-plugin-_tduhbpbizwvnvb6gxbmkrgchqe/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-montserrat\"}],\"variableName\":\"font\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/ui/mui/theme-registry.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/styles/global.scss");
